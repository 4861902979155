import React from 'react';
import { Router } from './router';
import { StyleCoreProvider } from "@prima-afp/prima-ui-design-system/dist/providers";
import { GlobalStyle } from '@prima-afp/prima-ui-elements/dist/layouts';
import '@prima-afp/prima-tailwind-utils/dist/tailwind-customized.css';

const App = () => {
  return (
    <GlobalStyle>
      <StyleCoreProvider assetsUrl={process.env.REACT_APP_ASSETS_URL}>
      <Router />
      </StyleCoreProvider>
    </GlobalStyle>
  );
};
export default App;
