import { ServiceFetcherCreate } from "@prima-afp/common-libs/dist/libs/http";

const DataUpdateWebServiceFetcher = ServiceFetcherCreate({
  tokenPathUrl: `${process.env.REACT_APP_URL_BASE_OAUTH}`,
  baseUrl: process.env.REACT_APP_API_URL_DATA_UPDATE_WEB,
  accessTokenGetter: () => {
    const getTokenAuth = localStorage.getItem("authToken");
    return getTokenAuth?.split(" ")[1] || "";
  },
  onGetToken: (response) =>
    localStorage.setItem("authToken", prop("authToken", response)),
  onError: (error) => {
    console.error("[DataUpdateWeb][ServiceFetcher]", error);
  },
});

export default DataUpdateWebServiceFetcher;
