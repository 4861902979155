import React from 'react';
import { TocBiometricValidationCommon } from '@prima-afp/prima-security/module';
import { FPhi as FPhiSelphID } from '@prima-afp/selphid-widget-component';
import { FPhi as FPhiSelphi } from '@prima-afp/selphi-widget-component';

// modules
import { BiometricValidationCommon as BiometricDataUpdateWeb } from '@prima-afp/prima-security/module';
import { BiometricsDataUpdateModule } from '../modules';
import * as MODULES_CONFIG from './config';
import { ApplicationRouter } from '../components';
import { AppProvider, useAppContext } from '../context';
import { Content, Wrapper } from 'modules/biometrics-data-update-new/pages/styles';
import { Sidebar } from 'modules/biometrics-data-update-new/components/Sidebar';

const Router = () => {
  const addGlobalConfig = (config = {}) => ({
    ...config,
    app: {
      useGlobalAppContext: useAppContext,
    },
  });

  return (
    <AppProvider biometric={{ FPhiSelphID, FPhiSelphi }}>
      <Wrapper>
        <Sidebar />
        <Content>
          <ApplicationRouter
            publicModules={[
              TocBiometricValidationCommon(addGlobalConfig(MODULES_CONFIG.genericProcedureBiometricTOCConfig)),
              BiometricDataUpdateWeb(addGlobalConfig(MODULES_CONFIG.biometricDataUpdateWebModuleConfig)),
              BiometricsDataUpdateModule(),
            ]}
          />
        </Content>
      </Wrapper>
    </AppProvider>
  );
};

export default Router;
