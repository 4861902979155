import AppSession from './AppSession';
import { generateModalError } from './ErrorHandler';

export const generateOAuthToken = async () => {
  try {
    const response = await fetch(process.env.REACT_APP_URL_BASE_OAUTH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'ocp-apim-subscription-key': process.env.REACT_APP_SUBSCRIPTION_KEY_OAUTH,
      },
      body: new URLSearchParams({
        client_id: process.env.REACT_APP_TOKEN_CLIENT_ID_NEW,
        client_secret: process.env.REACT_APP_TOKEN_CLIENT_SECRET_NEW,
        scope: process.env.REACT_APP_TOKEN_SCOPE_NEW,
        grant_type: 'client_credentials',
      }),
    });

    if (!response.ok) {
      throw new Error('Error al obtener el token OAuth');
    }

    const data = await response.json();
    const { access_token } = data; 

    AppSession.set({ accessToken: access_token });

    return access_token;
  } catch (error) {
    console.error("Error obteniendo el token OAuth:", error);
    throw new Error("Error al obtener el token OAuth");
  }
};

export const customFetch = async (url, options = {}) => {
  try {
    const accessToken = await generateOAuthToken();
    const headers = {
      ...options.headers,
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'ocp-apim-subscription-key': process.env.REACT_APP_SUBSCRIPTION_KEY_VALIDATE,
    };

    const body = options.body && typeof options.body === 'object' ? JSON.stringify(options.body) : options.body;

    const response = await fetch(url, {
      ...options,
      headers,
      body,
    });

    if (!response.ok) {
      throw new Error(`Error en la solicitud a ${url}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error realizando la solicitud:", error);
    generateModalError({
      errorCode: error.status || 500,
      endpoint: url,
      requestOptions: options,
      excludedEndPoints: [],
    });
    throw error;
  }
};

