import { useMutation } from "react-query";
import { useState } from "react";
import { saveDataForeign } from "../services";
import { useHistory } from "react-router-dom";

export const generateOldToken = async (fetchToken) => {
  const TOKEN_CLIENT_SECRET_OLD = process.env.REACT_APP_TOKEN_CLIENT_SECRET;
  const TOKEN_CLIENT_ID_OLD = process.env.REACT_APP_TOKEN_CLIENT_ID;
  const TOKEN_SCOPE_OLD = process.env.REACT_APP_TOKEN_SCOPE;

  const authHeaders = {
    client_secret: TOKEN_CLIENT_SECRET_OLD,
    client_id: TOKEN_CLIENT_ID_OLD,
    scope: TOKEN_SCOPE_OLD,
  };

  const bodyRequest = Object.keys(authHeaders)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(authHeaders[key]))
    .join("&");

  return new Promise((resolve, reject) => {
    fetchToken(
      {
        keys: bodyRequest,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_SUBSCRIPTION_KEY_OAUTH,
        },
      },
      {
        onSuccess: (data) => {
          const token = data?.access_token;
          if (token) {
            resolve(`Bearer ${token}`);
          } else {
            reject(new Error("No se pudo obtener un token válido para extranjeros."));
          }
        },
        onError: (error) => {
          console.error("Error al generar el token (flujo antiguo):", error);
          reject(error);
        },
      }
    );
  });
};

export const usePostChangeForeign = () => {  
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isErrorModalActive, setIsErrorModalActive] = useState(false);

  const postValidateForeign = async ({ body, token }) => {
    const requestBody = {
      documentNumber: body.documentNumber,
      documentType: body.documentType,
      email: body.email,
      idBiometric: body.idBiometric,
      validationType: "1",
    };
  
    const res = await saveDataForeign(requestBody, token);

    const data = await res;

    if (data.code !== "1") {    
      throw new Error(`Error en la respuesta del servidor: ${data.message}`);
    }
    return data;
  };

  const { mutate, isLoading, error, data, isSuccess } = useMutation(postValidateForeign, {
      onError: (error) => {
        setIsErrorModalActive(true);
        setErrorMessage(error.message);
      },
      onSuccess: (data) => {
        if (data.code === "1") {
          setErrorMessage(null);
          history.push("/actualizacion-datos-biometria/finished");
        } else {
          console.error("Error en la actualización:", data.message);
          setIsErrorModalActive(true);
          setErrorMessage(data.message);
        }
      },
    }
  );

  const handleCloseModal = () => {
    setIsErrorModalActive(false);
  };

  return {
    mutate,
    isLoading,
    error,
    errorMessage,
    data,
    isSuccess,
    handleCloseModal,
    modalSettings: {
      type: "alert",
      isActive: isErrorModalActive,
      title: "Error",
      content:
        "Lo sentimos, estamos trabajando para solucionar el inconveniente.",
      closeModal: handleCloseModal,
    },
  };
};
