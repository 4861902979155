import ServiceFetcher from "../../shared/libs/ServiceFetcher";

export const getHeaderQAuth = async (keys, headers) => {
  const response = await fetch(`${process.env.REACT_APP_URL_BASE_OAUTH}`, {
    method: "POST",
    body: keys,
    headers: headers,
  });

  if (!response.ok) {
    throw new Error("Failed to fetch Ocp-Apim-SubKey");
  }

  const data = await response.json();
  return data;
};

export const getDocuments = (headers) =>
  ServiceFetcher(
    `${process.env.REACT_APP_API_URL_DATA_UPDATE_WEB}/update-data-affiliated/`,
    {
      headers: headers,
      method: "GET",
    }
  );

export const validateDocument = (validateDocumentBody, headers) =>
  ServiceFetcher(
    `${process.env.REACT_APP_API_URL_DATA_UPDATE_WEB}/update-data-affiliated/validate-document`,
    {
      headers: headers,
      method: "POST",
      body: validateDocumentBody,
    }
  );

export const obtenerDatos = (validateDocumentBody, headers) =>
  fetch(
    `${process.env.REACT_APP_API_URL_DATA_GET_DOCUMENTS}`,
    {
      headers: headers,
      method: "POST",
      body: JSON.stringify(validateDocumentBody),
    }
  ).then(response => response.json())
   .catch(error => {
     console.error("Error en la solicitud obtenerDatos:", error);
     throw error;
   });

export const saveDataForeign = (saveDataBody, token) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token,
  };

  return ServiceFetcher(
    `${process.env.REACT_APP_API_URL_DATA_UPDATE_WEB}/update-data-affiliated/foreign`,
    {
      headers: headers,
      method: "POST",
      body: saveDataBody,
    }
  );
};
