import React, { useRef, useState, useEffect } from "react";
import { MainTitle } from "../../../shared/components/MainTitle";
import { Text } from "../../../shared/components/Text";
import { MainContainer } from "../../components/MainContainer";
import { StyledButton } from "../../components/Button/styles";
import Logo from "../../../shared/assets/images/prima-afp-logo-orange.svg";
import {
  FormContainerHome,
  InputSelectWrapper,
  StyledText,
  FormContainer,
  TextStyled,
  NavStyledHome,
} from "../styles";
import { Input, Option, Select, Progress } from "@prima-afp/prima-ui-elements/dist/core";

import ReCAPTCHA from "react-google-recaptcha";
import { GTMEvents } from "../../../shared/utils/GTMevents";
import { ErrorModal } from 'modules/shared/components/Error';
import { idDocumentOptions } from "../../../shared/utils/index";
import { useDataDocument } from "../../hooks/useDataDocument";
import { useGetTokenQauth } from "../../hooks/useGetTokenQauth";

export default function FormPage() {
  const defaultDocumentCode = "00";
  const defaultDocumentOption = idDocumentOptions.find(option => option.code === defaultDocumentCode);
  const [identificationDocumentType, setIdentificationDocumentType] = useState(defaultDocumentOption?.code || "");
  const [identificationDocumentNumber, setIdentificationDocumentNumber] = useState("");
  const [maxLength, setMaxLength] = useState(8);
  const [minLength, setMinLength] = useState(8);
  
  const TOKEN_CLIENT_SECRET = process.env.REACT_APP_TOKEN_CLIENT_SECRET_NEW;
  const TOKEN_CLIENT_ID = process.env.REACT_APP_TOKEN_CLIENT_ID_NEW;
  const TOKEN_SCOPE = process.env.REACT_APP_TOKEN_SCOPE_NEW;

  const [authHeaders, setAuthHeaders] = useState({
    client_secret: TOKEN_CLIENT_SECRET,
    client_id: TOKEN_CLIENT_ID,
    scope: TOKEN_SCOPE,
  });
  const [authToken, setAuthToken] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const reCaptchaRef = useRef();

  const { mutate: validateDocument, isLoading, isError, errorMessage, handleCloseModal, modalSettings, isSuccess } = useDataDocument();

  const { mutate: fetchToken } = useGetTokenQauth({
    onSuccess: (data) => {
      const token = data?.access_token;
      if (token) {
        setAuthToken(`Bearer ${token}`);
        localStorage.setItem("accessToken", token);
      } else {
        console.error("No se pudo obtener un token válido.");
      }
    },
    onError: (error) => {
      console.error("Error al obtener el token:", error);
    },
  });

  useEffect(() => {
    setMaxLength(defaultDocumentOption?.maxlength || 8);
    setMinLength(defaultDocumentOption?.minlength || 8);
  }, []);

  const handleDocumentTypeChange = (val) => {
    const selectedOption = idDocumentOptions.find((option) => option.code === val);
    setIdentificationDocumentType(val);
    setMaxLength(selectedOption?.maxlength || 9);
    setMinLength(selectedOption?.minlength || 4);
  };

  const handleGenerateToken = async () => {

    const bodyRequest = Object.keys(authHeaders)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(authHeaders[key]))
      .join("&");

    return new Promise((resolve, reject) => {
      fetchToken(
        {
          keys: bodyRequest,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_SUBSCRIPTION_KEY_OAUTH,
          },
        },
        {
          onSuccess: (data) => {
            const token = data?.access_token;
            if (token) {
              resolve(token);
            } else {
              reject(new Error("No se pudo obtener un token válido."));
            }
          },
          onError: (error) => {
            console.error("Error al generar el token:", error);
            reject(error);
          },
        }
      );
    });
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    setIsButtonDisabled(true);

    let token = authToken;
    if (!token) {
      try {
        token = await handleGenerateToken();
        setAuthToken(`Bearer ${token}`);
      } catch (error) {
        console.error("Error al generar el token:", error);
        setIsButtonDisabled(false);
        return;
      }
    }

    const recaptchaToken = await reCaptchaRef.current.executeAsync();

    validateDocument({
      body: {
        documentType: identificationDocumentType,
        documentNumber: identificationDocumentNumber,
        recaptchaToken: recaptchaToken,
      },
      token: `Bearer ${token}`,
    }, {
      onSettled: () => {
        setIsButtonDisabled(false);
      }
    });
  };

  return (
    <>
      <MainContainer>
        <NavStyledHome>
          <div className="tw-flex tw-flex-wrap tw-h-full tw-justify-between tw-content-center">
            <img src={Logo} alt="logo PRIMA" />
            <TextStyled>Ayer. Hoy. Siempre</TextStyled>
          </div>
        </NavStyledHome>
        <div className="tw-mt-2 tw-mt-0@lg">
          <MainTitle>Actualiza tus medios de contacto</MainTitle>
          <Text className="tw-mt-2">
            Por tu seguridad, realizaremos una validación facial y una vía SMS.
            Te recomendamos tener a la mano una computadora con cámara y tu
            celular.
          </Text>
          <StyledText className="tw-mt-1 tw-mb-2">
            Ingresa tu documento de identidad para identificarte:
          </StyledText>
        </div>
        <div className="tw-text-center tw-mt-2">
          <form onSubmit={handleSubmit} noValidate>
            <FormContainerHome className="tipoDocumento">
              <InputSelectWrapper>
                <Select
                  value={identificationDocumentType}
                  className="tipoDocumento-select"
                  dropdownClassName="tipoDocumento-dropdown"
                  onChange={(val) => handleDocumentTypeChange(val)}
                >
                  {idDocumentOptions.map((document) => (
                    <Option value={document.code} key={document.code}>
                      {document.value}
                    </Option>
                  ))}
                </Select>
                <Input
                  name="numberOfDocument-input"
                  className="tipoDocumento-input"
                  type="text"
                  maxLength={maxLength}
                  placeholder="Ingrese su número de documento"
                  onChange={(e) =>
                    setIdentificationDocumentNumber(e.target.value)
                  }
                />
              </InputSelectWrapper>
            </FormContainerHome>
            <FormContainer>
              <StyledButton
                onClick={GTMEvents.BtnValidateDocument}
                className="tw-flex tw-w-full button-wrap-valid tw-relative"
                type="submit"
                disabled={isButtonDisabled || identificationDocumentNumber.length < minLength}
              >
                {isLoading && (
                  <span className={'progress-inline'}>
                    <Progress theme={'white'} showMessages={false} />
                  </span>
                )}
                <span>
                  {isLoading
                    ? 'Validando...'
                    : isError
                    ? 'Error'
                    : isSuccess
                    ? 'Validado'
                    : 'Validar'}
                </span>
              </StyledButton>
            </FormContainer>
          </form>
        </div>
        <ErrorModal closeModal={handleCloseModal} settings={modalSettings} />
      </MainContainer>
      <ReCAPTCHA
        ref={reCaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
        size="invisible"
      />
    </>
  );
}
