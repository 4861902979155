import React, { useEffect, useState } from 'react';
import { StepsCircle } from '@prima-afp/prima-ui-elements/dist/core';
import { MainContainer } from '../../components/MainContainer';
import { Navbar } from '../../components/Navbar';
import { Steps } from '../styles';
import { OTPAuthenticator } from '@prima-afp/prima-security/module';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { usePostChangeForeign, generateOldToken } from '../../hooks/usePostChangeForeign';
import { useQueryClient } from 'react-query';
import { ErrorModal } from '../../../shared/components/ErrorModal';
import { customFetch } from '../../../shared/libs/customFetch';
import { useGetTokenQauth } from '../../hooks/useGetTokenQauth'; 

const initialState = [
  {
    id: 1,
    title: 'Datos de contacto',
    current: true,
    active: false,
  },
  {
    id: 2,
    title: 'Validación de identidad',
    current: false,
    active: false,
  },
];

export const StepOtpVerification = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const history = useHistory();
  const authToken = queryClient.getQueryData('authToken');
  const { mutate, modalSettings } = usePostChangeForeign();
  const [isErrorModalActive, setIsErrorModalActive] = useState(false);

  const otpValue = JSON.parse(localStorage.getItem('otpVerifycate'));
  const validateDocument = JSON.parse(localStorage.getItem('validateDocument'));
  const storageHeader = localStorage.getItem('authToken'); 

  const { userWebDataDto } = validateDocument || {};
  const state = location?.state || {};

  const pathsOTPDataDNI = {
    sendEmailCode: `${process.env.REACT_APP_API_URL_ACT_DATOS}/v1/generar-otp-email`,
    sendSMSCode: `${process.env.REACT_APP_API_URL_ACT_DATOS}/v1/generar-otp-sms`,
    validateEmailCode: `${process.env.REACT_APP_API_URL_ACT_DATOS}/v1/validar-otp-email`,
    validateSMSCode: `${process.env.REACT_APP_API_URL_ACT_DATOS}/v1/validar-otp-sms`,
    dataUpdate: `${process.env.REACT_APP_API_URL_ACT_DATOS}/v1/actualizar-datos`,
  };

  const affiliateDataUpdate = {
    // act datos biometria zona publica
    documentType: otpValue?.documentType,
    documentNumber: otpValue?.documentNumber,
    phoneNumber: otpValue?.phoneNumber,
    email: otpValue?.email,
    idBiometric:
    state?.idProcessStore,
  };

  const { mutate: fetchToken } = useGetTokenQauth({ 
    onSuccess: (data) => {
      const token = data?.access_token;
      if (token) {
        setAuthToken(`Bearer ${token}`);
        localStorage.setItem("authToken", `Bearer ${token}`);
      } else {
        console.error("No se pudo obtener un token válido.");
      }
    },
    onError: (error) => {
      console.error("Error al obtener el token:", error);
    },
  });

  const handleSubmit = async () => {
    let finalToken;
    try {
      finalToken = await generateOldToken(fetchToken);
      localStorage.setItem("authToken", finalToken);
    } catch (error) {
      console.error("Error al generar el token (flujo antiguo):", error);
      setIsErrorModalActive(true);
      return;
    }

    if (otpValue?.documentType !== '00') {
      await mutate({
        body: {
          documentType: otpValue?.documentType,
          documentNumber: otpValue?.documentNumber,
          email: otpValue?.email,
          idBiometric: state?.requestIdValidation,
          validationType: '1',
        },
        token: finalToken,
      });
    }
  };

  const documentType = validateDocument?.documentType;

  const handleFinish = () => {
    history.push('/actualizacion-datos-biometria/finished');
  };

  const onAccess = (value) => {
    if (value === 'success') {
      handleFinish();
    }
    if (value === 'failed') {
      window?.location?.replace('');
    }
  };

  useEffect(() => {
    if (otpValue && otpValue.documentType && otpValue.documentType !== '00') {
      handleSubmit();
    }
  }, [otpValue?.documentType]);

  return (
    <>
    {documentType === '00' && (
        <MainContainer>
          <Navbar back={true} />
          <div className='tw-mb-2'>
            <Steps>
              <StepsCircle center steps={initialState} />
            </Steps>
          </div>
          <OTPAuthenticator
            affiliate={affiliateDataUpdate}
            serviceFetcher={customFetch}
            paths={pathsOTPDataDNI}
            onAccess={(value) => onAccess(value)}
            headers={authToken ? { ...authToken } : { ...storageHeader }}
            layoutType={'EMBED'}
            isPublic={true}
          />
        </MainContainer>
      )}
      <ErrorModal
        closeModal={() => setIsErrorModalActive(false)}
        settings={{
          ...modalSettings,
          isActive: isErrorModalActive,
          closeModal: () => setIsErrorModalActive(false),
        }}
      />
    </>
  );
};
