import { prop } from 'ramda';
import { ServiceFetcherCreatePublic } from '@prima-afp/common-libs/dist/libs/http';

const TOKEN_REQUEST_OPTIONS = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  body: new URLSearchParams({
    'client_id': process.env.REACT_APP_TOKEN_CLIENT_ID_NEW,
    'client_secret': process.env.REACT_APP_TOKEN_CLIENT_SECRET_NEW,
    'scope': process.env.REACT_APP_TOKEN_SCOPE_NEW,
  })
};

const STORAGE_ACCESS_TOKEN_NAME = 'accessToken';

const DTServiceFetcher = ServiceFetcherCreatePublic({
  baseUrl: process.env.REACT_APP_API_URL_ACT_DATOS, 
  tokenUrl: process.env.REACT_APP_URL_BASE_OAUTH,
  tokenRequestOptions: TOKEN_REQUEST_OPTIONS,
  accessTokenGetter: () => localStorage.getItem(STORAGE_ACCESS_TOKEN_NAME),
  onError: (errorMessage) => {
    console.error(`[ServiceFetcher][onError]: ${errorMessage}`);
  },
  onGetToken: (response) => {
    localStorage.setItem(STORAGE_ACCESS_TOKEN_NAME, prop('access_token', response));
  }
});
const DATA_UPDATE_STEPS = [
  {
    title: "Verifica tus datos de contacto",
    description: "Siguiente paso: Valida tu identidad",
  },
  {
    title: "Valida tu identidad",
    description: "Último paso: Valida tus nuevos datos de contacto",
  },
  {
    title: "Valida tus nuevos datos de contacto",
    description: " ",
  },
];

export default {
  appName: 'actualizacionDatos',
  onlyBiometric: true,
  canal: 'actualizacionDatos',
  pathBase: '/toc-ad',
  pathRedirectGoBackToInitBiometry: '/actualizacion-datos-biometria',
  pathRedirectSuccessValidation: '/actualizacion-datos-biometria/otp',
  ServiceFetcher: DTServiceFetcher,
  needExecuteBeforeRedirectUrlAttemptsExceededModal: false,
  components: {
    AttemptsExceededModal: {
      description: 'Superaste el máximo de intentos',
      buttonLabel: 'Entendido',
      buttonPathRedirect: '/actualizacion-datos-biometria',
    },
    Stepper: {
      currentStep: 2,
      maxSteps: DATA_UPDATE_STEPS.length,
      size: 'medium',
      stepNames: DATA_UPDATE_STEPS,
    }
  },
  apiHooks: {
    useGetAttempts: {
      baseUrl: process.env.REACT_APP_API_URL_ACT_DATOS,
      endpoint: '/v1/obtener-intentos?documentNumber=',
      options: {
        method: 'GET',
      }

    },
    useCreateIdProcess: {
      baseUrl: process.env.REACT_APP_API_URL_ACT_DATOS,
      endpoint: '/v1/id-proceso',
      options: {
        method: 'PUT',
        body: ({ documentNumber, documentType }) => {
          return {
            documentNumber,
            documentType,
          };
        },
      }
    },
    useGetResult: {
      baseUrl: process.env.REACT_APP_API_URL_ACT_DATOS,
      endpoint: '/v1/obtener-resultado',
      options: {
        method: 'GET',
      },
    },
  },
};
